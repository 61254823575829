/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import {WebpMachine} from "webp-hero"
// export const onClientEntry = async () => {
//   if (typeof IntersectionObserver === "undefined") {
//     await import("intersection-observer")
//   }
  // const webpMachine = new WebpMachine()
  // webpMachine.polyfillDocument()
// }
export const onServiceWorkerUpdateReady = () => {
      window.location.reload()
  }
